.container {
  position: relative;
  border-bottom: 1px solid var(--color-neutral-600);
  overflow: hidden;
  padding: var(--size-spacing-07);
  text-align: center;
  background-color: var(--color-white);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon {
  display: flex;
  width: var(--size-spacing-05);
  height: var(--size-spacing-05);
  transform: translateY(-1px);

  svg {
    width: var(--size-spacing-05);
    height: var(--size-spacing-05);
    fill: var(--color-grey-300);
  }
}