@import '../../styles/breakpoints';

.subSection {
  background-color: var(--color-neutral-lighter);
  border: 1px solid var(--color-neutral-dark);
  border-radius: 3px;
  overflow: hidden;
}

.sectionHeading {
  text-align: center;
}

.section {
  border-top: 1px solid var(--color-neutral-600);
  padding: var(--size-spacing-05);

  @include device-large {
    padding: var(--size-spacing-05) 0;
  }
}
