@import '../../styles/breakpoints';

.causeDetails {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  gap: var(--size-spacing-05);

  @include device-medium {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.charityList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
