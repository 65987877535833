.owner {
  margin: 0;
}

.title {
  margin: 0;
}

.summary {
  font-size: var(--font-size-02);
  line-height: var(--font-size-05);
  font-weight: var(--font-weight-light);
  margin: 0;
  color: var(--color-grey-300);
}

.summary:first-letter {
  text-transform: capitalize;
}

.joinPageLink {
  white-space: nowrap;
}
